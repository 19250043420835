/*
 * @Author: Alan
 * @Date: 2023-06-14 15:11:45
 * @LastEditors: Alan
 * @LastEditTime: 2023-09-01 13:00:49
 * @Description: 404页面
 * @FilePath: /F1-M1-WEB-Code/pages/404.js
 */
import React from "react";
import Layout from "@/Layout/other";
import { PopUpLiveChat } from "$ACTIONS/util";

//export時會被靜態頁面取代(moveFile.js)
export default function Custom404() {
    return (
        <Layout>
            <div className="error-wrapper">
                <div className="error-img">
                    <img src="/cn/img/error404/errorImage.png" alt="404Image" />
                </div>
                <div className="error-right">
                    <div className="error-item-wrapper">
                        <div className="error-item">
                            <p className="error-text">无法找到页面</p>
                            <div className="error-icon">
                                <img
                                    src="/cn/img/error404/errorIcon.png"
                                    alt="404Icon"
                                />
                            </div>
                            <span className="error-mes">
                                您的网页暂时无法找到。若仍然无法打开页面，请您尽快联系客服。
                            </span>
                            <a
                                className="error-home-btn"
                                href="/"
                            >
                                <span>回到首页</span>
                            </a>
                            <button
                                className="error-chat-btn"
                                onClick={() => PopUpLiveChat(true)}
                            >
                                <img
                                    src="/cn/img/error404/icon-cs.svg"
                                    alt="customerServiceIcon"
                                />
                                <span>在线客服</span>
                            </button>
                            <div className="error-information">
                                <div className="error-info-text">
                                    <span>电子邮箱:</span>
                                    <a href="mailto:cs@fun88.com">cs@fun88.com</a>
                                </div>
                                {/* <div className="error-info-text">
                                    <span>热线电话:</span>
                                    <a href="#">+86 400 842 891</a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
